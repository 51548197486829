<aside class="card mt-4">
  <div class="card-header category-list-header">
    <div class="row">
      <div class="category-search-input-wrapper col-sm-9">
        <div class="input-group">
          <div class="input-group-prepend">
            <button
              pButton
              pRipple
              type="button"
              icon="fa fa-refresh"
              class="p-button-rounded p-button-text p-button-plain cat-list-header-btn"
              (click)="refreshCategoryList()"
            ></button>
            <button
              pButton
              pRipple
              type="button"
              class="p-button-rounded p-button-text p-button-plain cat-list-header-btn"
              icon="pi pi-search"
              (click)="toggleFilterType()"
              pTooltip="toggle search by N1QL/Category"
              showDelay="500"
            ></button>
          </div>
          <input
            type="search"
            class="form-control form-control-sm center"
            name="filter"
            id="filter"
            [placeholder]="'Search ' + (filterBy === FilterTypes.CategorySearch ? 'categories' : 'N1QL')"
            [(ngModel)]="filterText"
            (search)="clearFilter()"
            (input)="onFilter($event.target.value)"
            [class.n1ql-filter]="filterBy === FilterTypes.N1QLSearch"
          />
        </div>
      </div>
      <div class="col-sm-1">
        <button
          type="button"
          class="d-flex ml-n1 btn clear-filter-btn"
          pTooltip="Clear search filter"
          tooltipPosition="bottom"
          *ngIf="isFiltered"
          (click)="clearFilter()"
        >
          <img src="../../../../assets/images/Data-Clear-Filters-icon.png" class="clear-filters-icon" />
        </button>
      </div>
      <button class="btn btn-success" (click)="openAddModal()">Add</button>
    </div>
  </div>

  <div
    class="card-body category-list-container"
    [ngClass]="{
      'overflow-hidden': isLoadingCategories$ | async,
      'overflow-auto': (isLoadingCategories$ | async) === false
    }"
  >
    <ngx-spinner
      *ngIf="isLoadingCategories$ | async"
      [fullScreen]="false"
      bdColor="rgba(240,240,240,0.3)"
      size="large"
      color="#ddd"
      type="line-scale"
      class="spinner"
      ><span class="loading-message">Loading...</span>
    </ngx-spinner>
    <div class="category-list mt-n2" *ngIf="(isLoadingCategories$ | async) === false">
      <p-table
        #catListGrid
        [value]="filteredCategories$ | async"
        [scrollable]="true"
        scrollDirection="vertical"
        scrollHeight="flex"
        [virtualScroll]="true"
        responsiveLayout="scroll"
        styleClass="p-datatable-sm"
        [rows]="10000"
        selectionMode="single"
        (onRowSelect)="onRowSelectionChange($event)"
        [(selection)]="selectedRow"
      >
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-i="rowIndex">
          <tr [pSelectableRow]="rowData" class="cat-list-row">
            <td
              class="rule-col"
              [escape]="false"
              [pTooltip]="showRules(rowData)"
              [disabled]="!rowData.rules?.length"
              [appendTo]="'body'"
            >
              <button
                *ngIf="rowData.rules?.length"
                pButton
                pRipple
                type="button"
                class="p-button-rounded p-button-sm icon-btn rule-icon"
                (click)="openViewRulesModal(rowData)"
                label="R"
              ></button>
            </td>
            <td
              class="rule-col"
              [escape]="false"
              [pTooltip]="showPpfs(rowData)"
              [disabled]="!ppfDictionary[rowData.name]?.length"
              [appendTo]="'body'"
            >
              <button
                *ngIf="ppfDictionary[rowData.name]?.length"
                pButton
                pRipple
                type="button"
                class="p-button-rounded p-button-sm icon-btn ppf-icon"
                (click)="openViewPpfModal(rowData)"
                label="P"
              ></button>
            </td>
            <td class="cat-col">
              <div
                *ngIf="rowData.rules?.length"
                class="cursor-pointer rule-hover cat-cell"
                aria-label="show rules dialog"
                [disabled]="!ppfDictionary[rowData.name]?.length && !rowData.rules?.length"
                pTooltip="Click the 'P' or 'R' buttons to view associated rules and PPFs"
              >
                {{ rowData.name }}
              </div>
              <div *ngIf="rowData.rules?.length == 0">
                {{ rowData.name }}
              </div>
            </td>
            <td class="edit-col">
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-window-maximize"
                class="p-button-rounded p-button-text p-button-sm edit-category-icon cat-btn"
                pTooltip="Edit"
                tooltipPosition="left"
                (click)="openEditModal(rowData)"
              ></button>
            </td>
            <td class="delete-col">
              <button
                pButton
                type="button"
                icon="pi pi-trash"
                class="p-button-rounded p-button-text p-button-sm delete-manual-cd cat-btn"
                (click)="deleteConfirm($event, rowData)"
              ></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <small
      class="float-right category-count"
      pTooltip="Total with rules: {{ (categoriesWithRules$ | async)?.length | number }}"
      tooltipPosition="top"
      *ngIf="(isLoadingCategories$ | async) === false"
    >
      Total: {{ (categories$ | async)?.length | number }}
    </small>
  </div>
</aside>
