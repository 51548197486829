import { RuleType } from 'src/app/charge-cat/shared/enums';

export class Category implements CategoryReduced {
  cas = 0;
  chargeCatId = '';
  chargeDescriptions: string[] = [];
  chargeDescriptionSelectorN1QL = '';
  chargeDescriptionSelectorType: RuleType;
  chargeDescriptionsExclusions: string[] = [];
  cptList: string[] = [];
  createdBy = '';
  createdDate = '';
  description = '';
  displayName? = '';
  docId = '';
  hcpcsCpt = [];
  isDrgV = false;
  isChargeCapture = false;
  lastModified = '';
  modifiedBy = '';
  name = '';
  resolverDays = 0;
  rules: string[] = [];
  schemaVer: '';
  type: '';
  versionEditLog: string = '';

  constructor(name: string, description: string) {
    this.name = name;
    this.description = description;
  }
}

export interface CategoryReduced {
  docId: string;
  chargeCatId: string;
  name: string;
  resolverDays: number;
  rules: string[];
  description: string;
  isDrgV: boolean;
  isChargeCapture: boolean;
}

export interface PpfDictionary {
  [catName: string]: string[]; // key: category.name; value: PPF.Name
}

export interface CategoryN1qlSearchResult {
  docId: string;
}

export function createChargeCategory(name = '', description = '') {
  return new Category(name, description);
}
