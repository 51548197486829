import { Component, OnInit, OnDestroy } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';

import { UserState } from '../../store/user/user.state';
import { UserInfo } from 'src/app/_shared/models/userInfo';
import { environment } from 'src/environments/environment';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'cm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Select(UserState.userInfo) userInfo$: Observable<UserInfo>;
  isCollapsed = true;
  userReadNotifications = false;
  subs = new SubSink();
  buildNumber = environment.appVersion;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.subs.sink = this.userInfo$.subscribe(userInfo => {
      if (userInfo && !userInfo.imgUrl) {
        // createAvatarInitials()
      }
    });
  }

  readNotifications() {
    this.userReadNotifications = true;
  }

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  logout() {
    this.authService.logout({
      returnTo: document.location.origin,
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
