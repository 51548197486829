import { Component, Inject, ViewChild } from '@angular/core';
import { Form } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cm-edit-category-modal',
  templateUrl: './edit-category-modal.component.html',
  styleUrls: ['./edit-category-modal.component.scss']
})
export class EditCategoryModalComponent {
  @ViewChild('newCategoryForm', { static: false }) newCategoryForm: Form;
  disableEditing = false;
  isDirty = false;
  oldCategoryName = '';
  ruleSearchTerm = '';
  displayedRules: string[] = [];
  rules: string[] = [];

  ppfSearchTerm = '';
  displayedPpfs: string[] = [];
  ppfs: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<EditCategoryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
    if (data.hasRules) {
      this.disableEditing = true;

      this.rules = [...data.categoryRules].sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));
      this.displayedRules = this.rules;
    }

    if (data.ppfs?.length) {
      this.ppfs = [...data.ppfs].sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));
      this.displayedPpfs = this.ppfs;
    }
    this.oldCategoryName = data.name;
  }

  cancel(): void {
    this.data.name = '';
    this.dialogRef.close();
  }

  onServiceLineCheck(isChecked: boolean, serviceLine: any) {
    this.data[serviceLine] = isChecked;
    this.isDirty = true;
  }

  formIsValid(): boolean {
    let categoryNameChangeValidation = true;
    if(this.data.isDrgV) {
      categoryNameChangeValidation = (this.data.name === this.oldCategoryName ? true : false);
    }
    return categoryNameChangeValidation && this.data.name.trim() !== '' && (this.data.isDrgV || this.data.isChargeCapture);
  }

  enableEditInput() {
    this.disableEditing = false;
  }

  updateName() {
    this.data.name = this.data.name.trim();
    this.dialogRef.close(this.data);
  }

  searchRule() {
    const term = this.ruleSearchTerm.toLowerCase().trim();
    this.displayedRules = this.rules.filter(x => x.toLowerCase().trim().includes(term));
  }

  searchPpf() {
    const term = this.ppfSearchTerm.toLowerCase().trim();
    this.displayedPpfs = this.ppfs.filter(x => x.toLowerCase().trim().includes(term));
  }

  trackBy(i: number, rule: string) {
    return rule;
  }
}
