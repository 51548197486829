<h1 mat-dialog-title>{{ data.displayName }}</h1>
<div mat-dialog-content>
  <input
    pInputText
    type="text"
    [(ngModel)]="searchTerm"
    (input)="search()"
    placeholder="Search Rules"
    class="form-control form-control-sm mb-4"
  />
  <div class="overflow-hidden">
    <p-table
      [value]="displayedRows"
      [scrollable]="true"
      scrollDirection="both"
      responsiveLayout="scroll"
      styleClass="p-datatable-sm"
      rows="10000"
      scrollHeight="400px"
    >
      <ng-template pTemplate="body" let-rowData>
        <tr class="flex-column">
          <td>{{ rowData }}</td>
        </tr>
      </ng-template>
    </p-table>
    <small class="float-right">
      {{ displayedRows.length }} of {{ rows.length }}
    </small>
  </div>
</div>

<div mat-dialog-actions class="float-right">
  <button type="button" pButton class="btn btn-sm btn-secondary mr-2" (click)="close()">Close</button>
</div>
