import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface CategoryRuleModalData {
  rows: string[];
  displayName: string;
}

@Component({
  selector: 'cm-view-category-rules-modal',
  templateUrl: './view-category-rules-modal.component.html'
})
export class ViewCategoryRulesModalComponent {
  displayedRows: string[] = [];
  rows: string[];
  searchTerm = '';

  constructor(
    public dialogRef: MatDialogRef<ViewCategoryRulesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CategoryRuleModalData
  ) {
    // rules can be PPFs or rules
    this.rows = [...data.rows].sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));
    this.displayedRows = this.rows;
  }

  close(): void {
    this.dialogRef.close();
  }

  trackBy(i: number, name: string) {
    return name;
  }

  search() {
    const term = this.searchTerm.toLowerCase().trim();
    this.displayedRows = this.rows.filter(x => x.toLowerCase().trim().includes(term));
  }
}
