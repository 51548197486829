<h1 mat-dialog-title>Add Category</h1>
<form #newCategoryForm="ngForm" novalidate>
  <div mat-dialog-content class="overflow-hidden">
    <div class="form-group">
      <label for="name">Name:</label>
      <input
        type="text"
        class="form-control form-control-sm"
        #categoryName
        name="name"
        [(ngModel)]="data.name"
        [ngClass]="{ 'field-error': isDuplicate }"
        (input)="onAddingName()"
        (blur)="checkForDuplicates()"
        required
      />
      <div *ngIf="isDuplicate" class="error-text">The entered category name is already in use.</div>
      <div *ngIf="!isDuplicate">&nbsp;</div>
    </div>
    <div class="form-group">
      <label for="description">Description:</label>
      <textarea
        class="form-control"
        name="description"
        id="description"
        rows="3"
        [(ngModel)]="data.description"
      ></textarea>
    </div>
    <div class="form-group">
      <label for="timeDesignation">Time Designation:</label>
      <input
        type="number"
        class="form-control form-control-sm"
        #categoryName
        name="timeDesignation"
        [(ngModel)]="data.resolverDays"
      />
    </div>
    <h1 mat-dialog-title class="mb-1 mt-4">Service Lines</h1>
    <div class="sl-check-box">
      <p-checkbox name="isDrgV" [disabled]="true"  [binary]="true"
      > </p-checkbox>
      <label class="sl-check-box-label" for="isDrgV">DrgV</label>
    </div>
    <div class="mt-2 warn-text">
      Creating or editing new category name has been disabled for DrgV service line to complete parity with Charge Cat AI
    </div>
    <div class="sl-check-box">
      <p-checkbox name="isChargeCapture" [(ngModel)]="data.isChargeCapture" [binary]="true"
       >
      </p-checkbox>
      <label class="sl-check-box-label" for="isChargeCapture">Charge Capture</label>
    </div>
    <div *ngIf="!this.data.isDrgV && !this.data.isChargeCapture" class="mt-2 error-text">
      At least one service line must be selected
    </div>
    
    
  </div>
  <div mat-dialog-actions class="float-right">
    <button type="button" class="btn btn-sm btn-secondary mr-2" (click)="cancel()">Cancel</button>
    <button type="submit" class="btn btn-sm btn-primary" (click)="addCategory()" [disabled]="!formIsValid()">
      Add
    </button>
  </div>
</form>
