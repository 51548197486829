import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { SubSink } from 'subsink';

import { FilterMatchMode } from 'primeng/api';
import { Table } from 'primeng/table';
import { catchError, debounceTime, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { NotificationService } from 'src/app/_core/services/notification.service';
import { UserStoreFacade } from 'src/app/_core/store/user/user.store.facade';
import { Category, CategoryN1qlSearchResult, CategoryReduced, PpfDictionary } from 'src/app/_shared/models/category';
import { UserInfo } from 'src/app/_shared/models/userInfo';
import { NotificationType } from 'src/app/charge-cat/shared/enums';
import { messages } from 'src/app/charge-cat/shared/messages';
import { ClipboardOptions } from '../../shared/charge-description-grid/grid-clipboard-options';
import * as Actions from '../../store/app/app.actions';
import { ChargeCatSelectors } from '../../store/app/app.selectors';
import { ChargeCatStoreFacade } from '../../store/charge-cat-store.facade';
import { LoadingStateService } from '../../store/loading-state.service';
import { AddCategoryModalComponent } from '../add-category-modal/add-category-modal.component';
import { DeleteCategoryModalComponent } from '../delete-category-modal/delete-category-modal.component';
import { EditCategoryModalComponent } from '../edit-category-modal/edit-category-modal.component';
import {
  CategoryRuleModalData,
  ViewCategoryRulesModalComponent
} from '../view-category-rules-modal/view-category-rules-modal.component';

export enum FilterTypes {
  CategorySearch,
  N1QLSearch
}

@Component({
  selector: 'cm-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit, OnDestroy {
  // @ViewChild('filter', { static: true }) filter: ElementRef;
  @ViewChild('catListGrid') table: Table;

  // Store selectors
  @Select(ChargeCatSelectors.allCategories) categories$: Observable<CategoryReduced[]>;
  @Select(ChargeCatSelectors.categoriesWithRules) categoriesWithRules$: Observable<CategoryReduced[]>;
  @Select(ChargeCatSelectors.selectedCategory) selectedCategory$: Observable<Category>;
  isLoadingCategories$ = this.loadingStateService.isLoadingCategories$;
  n1qlMatches$ = new BehaviorSubject<CategoryN1qlSearchResult[]>(null);
  filterByN1ql$ = new Subject<string>();
  filteredCategories$ = this.observeFilteredCategories();
  user: UserInfo;
  categoryToDelete: Category;
  clipboardOptions = ClipboardOptions;
  isFiltered: boolean;
  filterText = '';
  filterBy = FilterTypes.CategorySearch;
  subs = new SubSink();
  ppfDictionary: PpfDictionary = {};

  // This allows the enum to be referenced in the template
  FilterTypes = FilterTypes;

  constructor(
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private facade: ChargeCatStoreFacade,
    private loadingStateService: LoadingStateService,
    private userFacadeService: UserStoreFacade
  ) {}

  ngOnInit() {
    this.spinner.show();
    this.facade.fetchCategories();
    this.facade.fetchCdmDataPaged();
    this.facade.fetchPpfsWithCategories();
    this.subscribeToN1qlSearch();
    this.subscribeToUserInfoSub();
    this.subscribeToPpfDictionary();
  }

  /**
   * Clears out all categories and selections the re-fetches the list.
   */
  refreshCategoryList() {
    const refresh = true;
    this.clearCategories();
    this.clearSelectedCategory();
    this.facade.fetchCategories(refresh);
  }

  /**
   * Clears out the list of categories from the store.
   */
  @Dispatch()
  clearCategories = () => new Actions.ClearCategories();

  /**
   * Clears the selected category from the store.
   */
  @Dispatch()
  clearSelectedCategory = () => new Actions.ClearSelectedCategory();

  /**
   * Adds a new category.
   * @param category Category to add
   */
  add(category: Category) {
    this.facade.addCategory(category);
  }

  /**
   * Deletes an existing category.
   * @description When the delete button for a category is clicked, that category
   * is then flagged for deletion, but not yet deleted. Once the user confirms the
   * deletion in the confirmation modal, the flagged category is then sent to be deleted.
   */
  delete() {
    this.facade.deleteCategory(this.categoryToDelete.chargeCatId);
  }

  toggleFilterType() {
    if (this.filterBy === FilterTypes.CategorySearch) {
      this.filterBy = FilterTypes.N1QLSearch;
    } else {
      this.filterBy = FilterTypes.CategorySearch;
    }
    // Set focus to the input once the selection has been made
    setTimeout(() => this.setFilterInputFocus());
  }

  /**
   * Handles selecting a category in the list.
   *
   * @description If the N1QL editor has a pending change then
   * prevent the selection of another category in the list and
   * show a notification indicating this.  Otherwise, allow the selection
   * of another category.
   *
   * @param event The rowSelectionEventArgs of the selected row
   */
  onRowSelectionChange(event: any) {
    if (this.facade.n1qlEditorHasPendingChanges()) {
      event.cancel = true; // prevents selection in the grid
      this.notificationService.notify(messages.warning.pendingN1QLChanges, NotificationType.Warning);
    } else {
      const { docId, chargeCatId } = event.data;
      this.select(docId, chargeCatId);
    }
  }

  /**
   * Handles the delete button for individual categories and
   * triggers the confirmation modal when deleting a category.
   *
   * @param event Used to prevent selecting the category when clicking the delete button
   * @param rowData Used to gather rowData
   */
  deleteConfirm(event: MouseEvent, rowData: Category | any) {
    event.stopPropagation();
    this.categoryToDelete = rowData;
    this.openDeleteModal();
  }

  /**
   * Updates the selected category's name and servicelines.
   *
   * @description Gets the latest snaphot of the selected category, updates
   * some properties then performs and update on it.
   */
  editCategory(data: Category) {
    const selectedCategory: Category = { ...data };
    selectedCategory.name = data.name;
    selectedCategory.description = data.description;
    selectedCategory.isDrgV = data.isDrgV || false;
    selectedCategory.isChargeCapture = data.isChargeCapture || false;
    selectedCategory.resolverDays = data.resolverDays;
    selectedCategory.modifiedBy = this.user.userId;
    selectedCategory.versionEditLog = data.versionEditLog;
    this.facade.editCategory(selectedCategory);
  }

  private subscribeToUserInfoSub() {
    this.subs.sink = this.userFacadeService.userInfo().subscribe(user => {
      this.user = user;
    });
  }

  /**
   * Sets the cursor focus to the search filter input of the type that was chosen.
   * @description There are two filter inputs in the template, but only one is shown at a time.
   * @example If option one is selected, then the input for option one will display and the focus will be set.
   */
  setFilterInputFocus() {
    document.getElementById('filter').focus();
  }

  onFilter(term: string) {
    if (this.filterBy === FilterTypes.CategorySearch) {
      this.filterByCategory(term);
    } else {
      this.filterByN1QL(term);
    }
  }

  /**
   * Filters the list by category name using the user entered text.
   *
   * @description Performs the filter using 'contains'.
   * @param term User entered search term
   */
  filterByCategory(term: string) {
    this.isFiltered = term !== '';
    this.table.filter(term, 'name', FilterMatchMode.CONTAINS);
  }

  private subscribeToN1qlSearch() {
    this.subs.sink = this.filterByN1ql$
      .pipe(
        debounceTime(600),
        switchMap((term: string) => this.facade.searchAllCategoriesN1ql(term)),
        catchError(err => {
          this.notificationService.notify(messages.error.fetchCategories, NotificationType.Error, err);
          return of(null);
        })
      )
      .subscribe((searchResults: CategoryN1qlSearchResult[]) => {
        this.n1qlMatches$.next(searchResults);
      });
  }

  observeFilteredCategories(): Observable<CategoryReduced[]> {
    return combineLatest([this.n1qlMatches$, this.categories$.pipe(startWith([]))]).pipe(
      map(([n1qlMatches, cats]) => {
        if (n1qlMatches === null) {
          return cats;
        }
        // get map of docIds
        const docIds = new Set<string>();
        for (const n1qlMatch of n1qlMatches) {
          docIds.add(n1qlMatch.docId);
        }
        return cats.filter(c => docIds.has(c.docId));
      })
    );
  }

  /**
   * Filters the list by N1QL statement using the user entered text.
   * @description Performs the filter using 'contains'.
   * @param term User entered search term
   */
  filterByN1QL(term: string) {
    this.isFiltered = term !== '';

    if (!this.isFiltered) {
      this.clearFilter();
      return;
    }

    this.filterByN1ql$.next(term);
  }

  /**
   * Resets the filter by restoring the full list of categories and clearing
   * the search textbox. It also hides the clear filter icon.
   */
  clearFilter() {
    this.table.filter('', 'name', FilterMatchMode.CONTAINS);
    this.isFiltered = false;
    this.filterText = '';
    this.n1qlMatches$.next(null);
  }

  /**
   * Handler for the add category modal.
   *
   * @description A blank object is passed in which is used by the modal to
   * hold the new data. Once the user clicks 'add', that data is returned
   * and the call to add the new category is made.
   */
  openAddModal() {
    const dialogRef = this.dialog.open(AddCategoryModalComponent, {
      width: '50%',
      autoFocus: true,
      data: {
        name: '',
        isDrgV: false,
        isChargeCapture: false
      }
    });
    // Handle the user submitting info in the modal
    this.subs.sink = dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.add(data);
      }
    });
  }

  /**
   * Handler for the edit category modal.
   *
   * @descrption When clicking the edit button, it opens the modal and selects
   * the category. Data used in the modal is passed in and the updated
   * name is returned.
   *
   * @param rowData Data in the cell of the selected category
   */
  openEditModal(category: Category) {
    const { docId, chargeCatId } = category;
    // wait for the api to get the cat data
    // then open the modal
    this.select(docId, chargeCatId)
      .pipe(
        take(1),
        switchMap(cat => {
          const dialogRef = this.dialog.open(EditCategoryModalComponent, {
            width: '60%',
            data: {
              name: cat.name,
              description: cat.description,
              categoryRules: cat.rules,
              ppfs: this.ppfDictionary[cat.name],
              hasRules: cat.rules?.length > 0,
              isDrgV: cat.isDrgV,
              isChargeCapture: cat.isChargeCapture,
              resolverDays: cat.resolverDays,
              versionEditLog: null
            }
          });

          return dialogRef.afterClosed().pipe(map(data => ({ data, cat })));
        }),
        filter(({ data }) => !!data),
        map(({ data, cat }) => {
          const updatedCat = Object.assign(
            { ...cat },
            {
              name: data.name,
              description: data.description,
              isDrgV: data.isDrgV,
              isChargeCapture: data.isChargeCapture,
              resolverDays: data.resolverDays,
              versionEditLog: data.versionEditLog
            }
          );

          return updatedCat;
        }),
        tap(data => this.editCategory(data))
      )
      .subscribe();
  }

  /**
   * Handler for delete category confirmation modal.
   *
   * @description When the delete button for a category is clicked
   * the modal opens and a message is passed into it.  If the user clicks
   * 'delete' in the modal, that information is returned and the call to
   * delete the category is made.
   */
  openDeleteModal() {
    const dialogRef = this.dialog.open(DeleteCategoryModalComponent, {
      width: '50%',
      data: {
        message: `Are you sure you want to delete ${this.categoryToDelete.name}?`
      }
    });
    // Handle the user submitting info in the modal
    this.subs.sink = dialogRef.afterClosed().subscribe(deleteCategory => {
      if (deleteCategory) {
        this.delete();
      }
    });
  }

  openViewRulesModal(rowData: Category) {
    this.openCategoryRulePpfViewModal(rowData.rules, rowData.displayName);
  }

  openViewPpfModal(rowData: Category) {
    this.openCategoryRulePpfViewModal(this.ppfDictionary[rowData.name], rowData.displayName);
  }

  openCategoryRulePpfViewModal(rows: string[], displayName: string) {
    if (!rows?.length) {
      return;
    }
    const config: MatDialogConfig<CategoryRuleModalData> = {
      width: '65%',
      data: { rows, displayName }
    };

    this.dialog.open(ViewCategoryRulesModalComponent, config);
  }

  private subscribeToPpfDictionary() {
    this.subs.sink = this.facade.ppfDictionary$.pipe(tap(x => (this.ppfDictionary = x))).subscribe();
  }

  showRules(rowData: Category) {
    return this.showTooltip(rowData.rules, 'rule');
  }

  showPpfs(category: Category) {
    const rows = this.ppfDictionary[category.name];
    return this.showTooltip(rows, 'ppf');
  }

  showTooltip(rows: string[], type: 'ppf' | 'rule') {
    if (!rows?.length) {
      return;
    }
    const maxDisplayedCount = 10;
    const isTruncated = rows.length > maxDisplayedCount;
    let warning = '';

    if (isTruncated) {
      warning = `<small>Click the "${type === 'ppf' ? 'P' : 'R'}" button to view all ${type === 'ppf' ? 'PPFs' : 'rules'}. (showing ${maxDisplayedCount} of ${rows.length})</small>`;
    }

    let temp: string = ``;
    rows.slice(0, maxDisplayedCount).forEach(item => {
      temp += `<li>${item}</li>`;
    });

    if (isTruncated) {
      temp += `<li>... ${rows.length - maxDisplayedCount} more</li>`;
    }

    return `${warning}<ul class='popover-rule-list'>${temp}</ul>`;
  }

  /**
   * Sets the selected category
   * @param docId The docId of the category to select.
   */
  private select(docId: string, chargeCatId: string): Observable<Category> {
    return this.facade.selectCategory(docId, chargeCatId);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
