import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingStateService {

  private loadingCategoriesSubject = new BehaviorSubject<boolean>(false);
  private loadingCategoryDeltaSubject = new BehaviorSubject<boolean>(false);
  private savingCategoryDeltaSubject = new BehaviorSubject<boolean>(false);

  isLoadingCategories$ = this.loadingCategoriesSubject.asObservable();
  isLoadingCategoryDelta$ = this.loadingCategoryDeltaSubject.asObservable();
  isSavingCategoryDelta$ = this.savingCategoryDeltaSubject.asObservable();

  setLoadingCategories(isLoading: boolean) {
    this.loadingCategoriesSubject.next(isLoading);
  }

  getLoadingCategoryDeltaValue() {
    return this.loadingCategoryDeltaSubject.getValue();
  }

  setLoadingCategoryDelta(isLoading: boolean) {
    this.loadingCategoryDeltaSubject.next(isLoading);
  }

  setIsSavingCategoryDelta(isSaving: boolean) {
    this.savingCategoryDeltaSubject.next(isSaving);
  }
}
