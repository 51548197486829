import { Component, OnDestroy, OnInit } from '@angular/core';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';

import { NotificationService } from 'src/app/_core/services/notification.service';
import { Category } from 'src/app/_shared/models';
import { EventBusService } from '../services/event-bus.service';
import { NotificationType } from '../shared/enums';
import { messages } from '../shared/messages';
import { ChargeCatSelectors } from '../store/app/app.selectors';
import { ChargeCatStoreFacade } from '../store/charge-cat-store.facade';
import { FunctionAppJob } from '../store/function-app-job/function-app-job-state.model';
import { FunctionAppJobSelectors } from '../store/function-app-job/function-app-job.selectors';
import { LoadingStateService } from '../store/loading-state.service';

@Component({
  selector: 'cm-category-definition',
  templateUrl: './category-definition.component.html',
  styleUrls: ['./category-definition.component.scss']
})
export class CategoryDefinitionComponent implements OnInit, OnDestroy {
  @Select(ChargeCatSelectors.selectedCategory) selectedCategory$: Observable<Category>;
  @SelectSnapshot(ChargeCatSelectors.isN1QLEditorDirty) hasPendingN1QLChanges: Observable<boolean>;
  @Select(FunctionAppJobSelectors.allJobs) jobs$: Observable<FunctionAppJob[]>;

  isLoadingCategories$: Observable<boolean> = this.loadingStateService.isLoadingCategories$;

  displayTopSlider: boolean;
  subs = new SubSink();

  constructor(
    private eventBus: EventBusService,
    private notificationService: NotificationService,
    private facade: ChargeCatStoreFacade,
    private loadingStateService: LoadingStateService
  ) {}

  ngOnInit() {
    this.subs.sink = this.eventBus.toggleTopSlider$().subscribe(toggle => (this.displayTopSlider = toggle));
    this.facade.fetchHcpcsCptList();
    this.facade.fetchTenantList();
    this.facade.fetchRevenueCodeList();
  }

  onChangeTab() {
    window.dispatchEvent(new Event('resize'));
  }

  toggleTopSlider() {
    this.eventBus.toggleTopSlider();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.hasPendingN1QLChanges) {
      this.notificationService.notify(messages.warning.pendingN1QLChanges, NotificationType.Warning);
      return false;
    }
    return true;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
