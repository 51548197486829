import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { SubSink } from 'subsink';

import { interval } from 'rxjs';
import { ApplicationInsightsService } from 'src/app/_core/services/app-insights-service.service';
import { NotificationService } from 'src/app/_core/services/notification.service';
import { Category, CdmChargeDescription } from 'src/app/_shared/models';
import { Utils } from 'src/app/_shared/utils';
import { EventBusService } from '../../services/event-bus.service';
import { NotificationType } from '../../shared/enums';
import { messages } from '../../shared/messages';
import { CategoryDelta, CategoryDeltaReport, CategoryDeltaRequest } from '../../shared/models';
import * as Actions from '../../store/app/app.actions';
import { ChargeCatSelectors } from '../../store/app/app.selectors';
import { ChargeCatStoreFacade } from '../../store/charge-cat-store.facade';
import { ChargeCatErrorSelectors } from '../../store/errors/error.selectors';
import { LoadingStateService } from '../../store/loading-state.service';

@Component({
  selector: 'cm-preview-modal',
  styleUrls: ['./preview-modal.component.scss'],
  templateUrl: 'preview-modal.component.html'
})
export class PreviewModalComponent implements OnInit, OnDestroy {
  @Select(ChargeCatErrorSelectors.getCategoryDeltaError) queryError$: Observable<any>;
  @SelectSnapshot(ChargeCatSelectors.selectedCategory) selectedCategory: Category;
  @SelectSnapshot(ChargeCatSelectors.isN1QLEditorDirty) hasPendingN1QLChanges: Observable<boolean>;
  isLoadingCategoryDelta$ = this.loadingStateService.isLoadingCategoryDelta$;
  isSavingCategoryDeltaPreview$ = this.loadingStateService.isSavingCategoryDelta$;
  @Select(ChargeCatSelectors.getActiveRunningTenants) activeRunningTenants: Observable<String[]>;

  static deltaRequestSize = 250;
  gridData: CategoryDelta;
  elapsedTime = '00:00';
  subs = new SubSink();
  interval: any;
  previewSubscription: Subscription;
  translateN1qlSub: Subscription;
  translateN1QLError = false;
  versionEditLog = '';

  constructor(
    public dialogRef: MatDialogRef<PreviewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private facade: ChargeCatStoreFacade,
    private appInsightsService: ApplicationInsightsService,
    private loadingStateService: LoadingStateService,
    private notificationService: NotificationService,
    private eventBusService: EventBusService
  ) {}

  ngOnInit() {
    this.startTimer();
    if (!this.hasPendingN1QLChanges) {
      this.setTempCategoryForN1QLStatement(this.selectedCategory);
    }
    this.fetchElasticQueryFromN1ql();
    this.setUpLoadingIndicator();
    this.facade.checkActiveTenants();
    this.subs.sink = interval(10000).subscribe(t => {
      this.facade.checkActiveTenants();
    });
  }

  @Dispatch()
  setTempCategoryForN1QLStatement = (tempCategory: Category) =>
    new Actions.SetTempCategoryForN1QLStatement(tempCategory);

  @Dispatch()
  setCategoryDeltaError = err => new Actions.SetCategoryDeltaError(err);

  save() {
    this.elapsedTime = '';
    this.eventBusService.setVersionEditLog(this.versionEditLog);
    this.facade.saveN1QL().add(() => {
      this.eventBusService.setVersionEditLog(null);
      this.dialogRef.close();
    });
  }

  cancel() {
    this.cancelDataApiRequest();
    this.dialogRef.close();
  }

  /**
   * Sets up a timer to show how much time has passed while loading the data
   */
  startTimer() {
    const timerStart = performance.now();
    this.interval = setInterval(() => {
      this.elapsedTime = Utils.elapsedTime(timerStart);
    }, 1000);
  }

  setUpLoadingIndicator() {
    this.subs.sink = this.isLoadingCategoryDelta$.subscribe(loading => {
      if (!loading) {
        clearInterval(this.interval);
      }
    });
  }

  getDataForGrids(data: CdmChargeDescription[]) {
    data.map(cdmItem => this.facade.mapAdditionalGridColumns(cdmItem));
    return data;
  }

  private fetchElasticQueryFromN1ql() {
    this.toggleLoadingCategoryDelta(true);
    this.previewSubscription = this.facade.translateN1qlToElastic().subscribe(
      eq => {
        if (typeof eq === 'object') {
          const elasticQuery = {
            elasticQuery: JSON.stringify(eq),
            chargeCatDocId: this.selectedCategory.docId,
            manualIncludeIds: this.selectedCategory.chargeDescriptions,
            manualExcludeIds: this.selectedCategory.chargeDescriptionsExclusions
          } as CategoryDeltaRequest;
          this.gridData = new CategoryDelta();

          return this.facade.fetchElasticDelta(elasticQuery).then(
            (cd: CategoryDeltaReport) => {
              this.gridData.descriptionsToAdd = this.getDataForGrids(cd.descriptionsToAdd);
              this.gridData.descriptionsToRemove = this.getDataForGrids(cd.descriptionsToRemove);
              this.gridData.noChangeCount = cd.countNoChange;
              this.gridData.descriptionsToRemoveCount = cd.countToRemove;
              this.gridData.descriptionsToAddCount = cd.countToAdd;
              this.toggleLoadingCategoryDelta(false);
              this.setCategoryDeltaError(null);
            },
            err => {
              this.handlePreviewError(err, 'Error getting charge category delta');
              this.appInsightsService.trackException({
                exception: err,
                error: new Error(
                  `Error getting charge category delta. Name: ${this.selectedCategory.name} Id: ${this.selectedCategory.docId}`
                )
              });
              console.log('ERROR fetching category delta: ', err);
            }
          );
        }
      },
      err => {
        this.toggleLoadingCategoryDelta(false);
        this.appInsightsService.trackException({
          exception: err,
          error: new Error(
            `Error translating N1QL to elastic. Name: ${this.selectedCategory.name} Id: ${this.selectedCategory.docId}`
          )
        });
        console.log('Error translating N1QL to elastic: ', err);
        this.notificationService.notify(messages.error.translateN1QLToElastic, NotificationType.Error);
        this.translateN1QLError = true;
        return;
      }
    );
    this.subs.sink = this.translateN1qlSub;
  }

  private toggleLoadingCategoryDelta(isLoading: boolean) {
    this.loadingStateService.setLoadingCategoryDelta(isLoading);
  }

  private handlePreviewError(result?: any, displayMsg?: string, apiError?: string) {
    this.toggleLoadingCategoryDelta(false);
    let error = null;
    if (result) {
      this.setCategoryDeltaError(displayMsg);
      if (result.errors) {
        error = result.errors[0];
      }
    } else {
      this.setCategoryDeltaError(apiError);
      error = apiError;
    }
    this.appInsightsService.trackException({ error });
  }

  /**
   * By unsubscribing from an active subscription, a cancellation token
   * will be sent to the API.
   */
  private cancelDataApiRequest() {
    const isLoading = this.loadingStateService.getLoadingCategoryDeltaValue();
    if (isLoading) {
      this.toggleLoadingCategoryDelta(false);
    }
    this.previewSubscription.unsubscribe();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    clearInterval(this.interval);
  }
}
