import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select } from '@ngxs/store';
import clonedeep from 'lodash.clonedeep';
import { FilterService, LazyLoadEvent } from 'primeng/api';
import { Calendar } from 'primeng/calendar';
import { MultiSelect } from 'primeng/multiselect';
import { Table } from 'primeng/table';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApplicationInsightsService } from 'src/app/_core/services/app-insights-service.service';
import { NotificationService } from 'src/app/_core/services/notification.service';
import { UserStoreFacade } from 'src/app/_core/store/user/user.store.facade';
import { Category } from 'src/app/_shared/models/category';
import { CdmChargeDescription, CdmTableData, ChargeDescription } from 'src/app/_shared/models/charge-description';
import { ColumnFilter } from 'src/app/_shared/models/column-filter';
import { Utils } from 'src/app/_shared/utils';
import { NotificationType } from 'src/app/charge-cat/shared/enums';
import { SubSink } from 'subsink';
import { EventBusService } from '../services/event-bus.service';
import { CdmTypes } from '../shared/enums';
import { messages } from '../shared/messages';
import {
  BulkChargeDescriptionListEdit,
  CdmPageFilter,
  ChargeDescriptionEdit,
  ChargeDescriptionSingleUpdateEdit,
  ChargeDescriptionUpdatesByServiceLine,
  QueryParameters,
  Tenant
} from '../shared/models';
import { CptHcpcsCodeDescription, RevenueCodeDescription } from '../store/app/app-state.model';
import { ChargeCatSelectors } from '../store/app/app.selectors';
import { ChargeCatStoreFacade } from '../store/charge-cat-store.facade';
import { CdmTableColumns } from './cdm-table-columns';

@Component({
  selector: 'cm-cdm-table',
  templateUrl: './cdm-table.component.html',
  styleUrls: ['./cdm-table.component.scss']
})
export class CdmTableComponent implements OnInit, OnDestroy {
  @ViewChild('cdmTable') cdmTable: Table;

  @Select(ChargeCatSelectors.cdmTableData) cdmTableData$: Observable<CdmTableData>;
  @Select(ChargeCatSelectors.tenantList) tenantList$: Observable<Tenant[]>;
  @Select(ChargeCatSelectors.hcpcsList) hcpcsList$: Observable<CptHcpcsCodeDescription[]>;
  @Select(ChargeCatSelectors.cptList) cptList$: Observable<CptHcpcsCodeDescription[]>;
  @Select(ChargeCatSelectors.getCptDictionary) cptDictionary$: Observable<any>;
  @Select(ChargeCatSelectors.getHcpcsDictionary) hcpcsDictionary$: Observable<any>;
  @Select(ChargeCatSelectors.revenueCodeList) revenueCodeList$: Observable<RevenueCodeDescription[]>;
  @Select(ChargeCatSelectors.revenueCodeDictionary) revenueCodeDictionary$: Observable<any>;
  @Select(ChargeCatSelectors.cdmTablePageCount) pageCount$: Observable<any>;
  @Select(ChargeCatSelectors.getSiDictionary) siDictionary$: Observable<any>;
  @Select(ChargeCatSelectors.getAmaDescrDictionary) amaDescrDictionary$: Observable<any>;
  @Select(ChargeCatSelectors.getApcDictionary) apcDictionary$: Observable<any>;
  @SelectSnapshot(ChargeCatSelectors.cdmChargeDescriptions) cdmDataSnapshot: ChargeDescription[];
  @SelectSnapshot(ChargeCatSelectors.allCategories) categories: Category[];

  cdmTablePageSize = this.facade.cdmTablePageSize;
  exportPageSize = this.facade.exportPageSize;
  bulkApply = 'bulkApply';
  singleUpdate = 'singleUpdate';
  editMode = this.singleUpdate;
  categoriesToDelete: Category[] = [];
  categoriesToAdd: Category[] = [];
  lastCdmPageFilter = '';
  cdmTablePageSizeViewValue = this.cdmTablePageSize;
  cols: ColumnFilter[] = CdmTableColumns;
  queryParameters = new QueryParameters();
  selectAllChecked = false;
  loading = false;
  loadingUpdates = false;
  multiSelectLoading = false;
  subs = new SubSink();
  updatingUIDisplayValues = false;
  reloadAfterExport = false;
  applyingColFilter = false;
  virtualCdmData: CdmChargeDescription[] = [];
  _selectedColumns: ColumnFilter[];
  showEditModal = false;
  enableEditButton = false;
  exportingToCsvInProcess = false;
  textFilterSubject: Subject<string> = new Subject();
  textDescriptionFilterSubject: Subject<string> = new Subject();
  filteredResults: Category[];
  categoriesForDescriptions: Category[] = [];
  selectedSlCategoriesForDescriptions: Category[] = [];
  allCategoriesForDescriptions = new ChargeDescriptionUpdatesByServiceLine();
  selectedRows: CdmChargeDescription[] = [];
  isFiltered = null;
  isLazy = true;
  selectedChargeDescriptionIds: string[] = [];
  pageCount = 0;
  user = null;
  hasFiltersApplied = false;
  hasLocalState = false;
  loadedRecordCount = 0;
  percentLoaded = 0;
  revCodeDictionary = {};
  cptDictionary = {};
  hcpcsDictionary = {};
  offsetMap = {};
  siDictionary = {};
  amaDictionary = {};
  apcDictionary = {};
  exportFailure = false;
  selectedServiceLine = 'all';
  cdmPageFilter: CdmPageFilter = this.facade.getDefaultFilterParams();
  imagePath = '../../../assets/images/';
  cdmTableData: CdmTableData;
  textFilterOptions = [
    {
      label: 'Contains',
      value: 'LIKE',
      iconSrc: this.imagePath + 'contains.svg',
      selectedIconSrc: this.imagePath + 'contains-selected.svg'
    },
    {
      label: 'Begins with',
      value: 'BEGINS WITH',
      iconSrc: this.imagePath + 'starts-with.svg',
      selectedIconSrc: this.imagePath + 'starts-with-selected.svg'
    },
    {
      label: "Doesn't contain",
      value: 'NOT LIKE',
      iconSrc: this.imagePath + 'does-not-contain.svg',
      selectedIconSrc: this.imagePath + 'does-not-contain-selected.svg'
    }
  ];
  textTokenFilterOptions = [
    {
      label: 'Contains',
      value: 'SATISFIESLIKE',
      iconSrc: this.imagePath + 'contains.svg',
      selectedIconSrc: this.imagePath + 'contains-selected.svg'
    },
    {
      label: "Doesn't contain",
      value: 'SATISFIES NOT LIKE',
      iconSrc: this.imagePath + 'does-not-contain.svg',
      selectedIconSrc: this.imagePath + 'does-not-contain-selected.svg'
    }
  ];
  inFilterOptions = [
    {
      label: 'Contains',
      value: 'contains',
      iconSrc: this.imagePath + 'contains.svg',
      selectedIconSrc: this.imagePath + 'contains-selected.svg'
    },
    {
      label: "Doesn't contain",
      value: 'doesNotContain',
      iconSrc: this.imagePath + 'does-not-contain.svg',
      selectedIconSrc: this.imagePath + 'does-not-contain-selected.svg'
    }
  ];
  inFilterOptions2 = [
    {
      label: 'Contains',
      value: 'IN',
      iconSrc: this.imagePath + 'contains.svg',
      selectedIconSrc: this.imagePath + 'contains-selected.svg'
    },
    {
      label: "Doesn't contain",
      value: 'NOT IN',
      iconSrc: this.imagePath + 'does-not-contain.svg',
      selectedIconSrc: this.imagePath + 'does-not-contain-selected.svg'
    }
  ];
  constructor(
    public dialog: MatDialog,
    private facade: ChargeCatStoreFacade,
    private notificationService: NotificationService,
    private eventBus: EventBusService,
    private appInsightsService: ApplicationInsightsService,
    private userFacade: UserStoreFacade,
    private primengFilterService: FilterService
  ) {
    this.removeStatePropertiesFromStorage();
    this.primengFilterService.filters['doesNotContain'] = (value, filter): boolean => {
      if (filter === undefined || filter === null || filter.trim() === '') {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      return value.toString().indexOf(filter.toString()) == -1;
    };
  }

  /**
   * This input is used for retrieving the columns to be
   * used for the showing/hiding list in the gear overlay
   */
  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  /**
   * When toggling column visibility, by default it will put the column
   * at the end.  This maintains the original order, or the order stored
   * in localstorage when turning a column back on.
   */
  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter(col => val.includes(col));
  }

  ngOnInit() {
    this.checkForStateInLocalStg();
    this.getColumnState();
    this.fetchFilterValues();
    this.initCdmTableSubscriptions();
  }

  private initCdmTableSubscriptions() {
    this.initPageCountSub();
    this.initTextFilterSub();
    this.initTextDescFilterSub();
    this.initCdmTableLoadingSub();
    this.initCdmTableUpdatesSub();
    this.initUiDisplayValueUpdatesSub();
    this.initUserInfoSub();
  }

  private initUserInfoSub() {
    this.subs.sink = this.userFacade.userInfo().subscribe(user => {
      this.user = user;
    });
  }

  private initUiDisplayValueUpdatesSub() {
    this.subs.sink = this.eventBus.getUpdatingUIDisplayValues().subscribe(loading => {
      if (this.exportingToCsvInProcess && !loading) {
        this.cdmTable.exportCSV();
        this.exportingToCsvInProcess = false;
        this.reset();
        this.reloadAfterExport = true;
        this.fetchCdmData(this.createLazyLoadMetadata());
      }
      this.updatingUIDisplayValues = loading;
    });
  }

  private initCdmTableUpdatesSub() {
    this.subs.sink = this.eventBus.getCDMTableUpdatesLoading().subscribe(loading => {
      this.loadingUpdates = loading;
      if (!loading) {
        this.selectedRows = [];
        this.selectedChargeDescriptionIds = [];
        this.enableEditButton = false;
        this.selectAllChecked = false;
      }
    });
  }

  private initCdmTableLoadingSub() {
    this.subs.sink = this.eventBus.getCDMTableLoading().subscribe(loading => {
      this.loading = loading;
    });
  }

  private initTextDescFilterSub() {
    this.subs.sink = this.textDescriptionFilterSubject.subscribe(col => {
      let colWithFilter = this.cols.find(c => c.field === col);
      if (colWithFilter && colWithFilter.tempSelections.length === 0) {
        this.removeFilter(colWithFilter);
      } else {
        this.applyFilter(col);
      }
    });
  }

  private initTextFilterSub() {
    this.subs.sink = this.textFilterSubject.pipe(debounceTime(2000)).subscribe(column => {
      let colWithFilter = this.cols.find(c => c.field === column);
      if (colWithFilter && colWithFilter.tempSelections.length === 0) {
        this.removeFilter(colWithFilter);
      } else {
        this.applyFilter(column);
      }
    });
  }

  addOrRemoveCategory(category: Category, list: string) {
    if (!this.updateListContainsCategory(category)) {
      if (list === 'add') {
        this.categoriesToAdd.push(category);
      }
      if (list === 'remove') {
        this.categoriesToDelete.push(category);
      }
    } else if (list === 'remove' && this.categoriesToAdd.some(cat => cat.chargeCatId === category.chargeCatId)) {
      this.categoriesToAdd = this.categoriesToAdd.filter(cat => cat !== category);
    } else {
      this.notificationService.notify('Category already in add or remove list', NotificationType.Warning);
    }
  }

  private updateListContainsCategory(category: Category) {
    return (
      this.categoriesToAdd.some(cat => cat.chargeCatId === category.chargeCatId) ||
      this.categoriesToDelete.some(cat => cat.chargeCatId === category.chargeCatId)
    );
  }

  removeFromAddOrDeletes(category: Category, list: string) {
    if (list == 'add') {
      this.categoriesToAdd = this.categoriesToAdd.filter(cat => cat !== category);
      this.selectedSlCategoriesForDescriptions = this.selectedSlCategoriesForDescriptions.filter(
        cat => cat !== category
      );
    }
    if (list == 'remove') {
      this.categoriesToDelete = this.categoriesToDelete.filter(cat => cat !== category);
      this.selectedSlCategoriesForDescriptions = [...this.selectedSlCategoriesForDescriptions, category];
    }
  }

  selectAll() {
    this.selectedRows = this.selectAllChecked ? [...this.virtualCdmData] : [];
    if (this.selectedRows.length > 0) {
      this.selectedChargeDescriptionIds = this.selectedRows.map(chargeDescription => chargeDescription.docId);
    }
    this.enableEditButton = this.selectedRows.length > 0;
  }

  private initPageCountSub() {
    this.subs.sink = this.pageCount$.subscribe(
      result => {
        if (result.cdmDataPagedCount !== null && result.cdmDataPagedCount !== undefined) {
          this.pageCount = result.cdmDataPagedCount;
          if (this.pageCount == 0) {
            this.eventBus.setCDMTableLoading(false);
          }
          this.cdmPageFilter.paging.pageSize = this.cdmTablePageSize;
          this.cols.forEach(col => {
            col.uniqueDataNonLazyFilter = col.uniqueData;
          });
          if (this.pageCount > this.cdmTablePageSize) {
            this.virtualCdmData = Array.from({ length: this.cdmTablePageSize });
          } else {
            this.virtualCdmData = Array.from({ length: result.cdmDataPagedCount });
          }
          this.virtualCdmData = [...this.virtualCdmData];
          this.handleVirtualReset();
        }
      },
      err => {
        this.eventBus.setCDMTableLoading(false);
      }
    );
  }

  private getColumnState() {
    if (localStorage.getItem('cdmTableColVisibility')) {
      const valMap = JSON.parse(localStorage.getItem('cdmTableColVisibility'));
      this.selectedColumns = valMap.map(val => this.cols.find(col => col.field == val));
    } else {
      this.selectedColumns = [...this.cols];
    }
  }

  private checkForStateInLocalStg() {
    if (localStorage.getItem('cdmTableColVisibility') || localStorage.getItem('cdmtable')) {
      this.hasLocalState = true;
    }
  }

  exportToExcel() {
    this.exportFailure = false;
    if (this.pageCount > 100000) {
      this.notificationService.notify(messages.info.exportOverLimit, NotificationType.Info);
      return;
    }
    if (this.cdmPageFilter.paging.pageSize >= this.pageCount) {
      this.cdmTable.exportCSV();
    } else {
      this.cdmPageFilter.paging.pageSize = this.exportPageSize;
      this.exportingToCsvInProcess = true;
      this.fetchCdmDataForExport();
    }
  }

  setColumnState(val) {
    this.hasLocalState = true;
    const valMap = val.map(e => e.field);
    localStorage.setItem('cdmTableColVisibility', JSON.stringify(valMap));
  }

  onTextFilter(col: ColumnFilter) {
    this.textFilterSubject.next(col.field);
  }

  onMultiSelectEvent(event: any, col: any) {
    this.cdmTable.filter(event.value, col.field, 'in');
  }

  onDescriptionTextFilter(col: ColumnFilter) {
    this.textDescriptionFilterSubject.next(col.field);
  }

  fetchCdmData(event: LazyLoadEvent) {
    if (event) {
      this.setOrderBy(event);
      let currentPageFilters = JSON.stringify(this.cdmPageFilter);
      if (currentPageFilters == this.lastCdmPageFilter && !this.reloadAfterExport && !this.applyingColFilter) {
        return;
      }
      if (this.applyingColFilter) {
        this.applyingColFilter = false;
      }
      this.eventBus.setCDMTableLoading(true);
      this.facade.fetchCdmDataPaged(this.cdmPageFilter);
      this.lastCdmPageFilter = JSON.stringify(this.cdmPageFilter);
      this.reloadAfterExport = false;
    }
  }

  fetchCdmDataForExport() {
    this.eventBus.setCDMTableLoading(true);
    return this.facade.fetchCdmDataPagedExport(this.cdmPageFilter);
  }

  isLazySortCol(column) {
    let hasFilter = this.cdmPageFilter.filters.find(col => col.field === column.field);
    if (hasFilter && hasFilter.isLazyLoaded && column.uniqueDataNonLazyFilter.length !== column.uniqueData.length) {
      column.uniqueDataNonLazyFilter = [...column.uniqueData];
    }
    if (hasFilter) {
      return hasFilter.isLazyLoaded;
    }
  }

  applyFilter(colField) {
    this.applyingColFilter = true;
    this.setPageFilters(colField);
    this.reset();
    this.handleVirtualReset();
  }

  /**
   * Reloads data from API
   */
  private handleVirtualReset() {
    if (!this.cdmTable) {
      return;
    }
    this.cdmTable.first = 0;
    this.cdmTable.firstChange.emit(this.cdmTable.first);
    this.cdmTable.anchorRowIndex = null;
    this.cdmTable.onLazyLoad.emit(this.createLazyLoadMetadata());
    if (this.pageCount > 0 && this.isLazy) {
      setTimeout(() => {
        this.cdmTable.scrollToVirtualIndex(1);
        setTimeout(() => {
          this.cdmTable.scrollToVirtualIndex(0);
        }, 100);
      }, 100);
    }
  }

  /**
   * Handler for removing a multiselect filter.
   * @description All selections (temp and filtered) get cleared out and any previous filters
   * that were applied are removed from the cdmPageFilter object, so that it can be used to refetch.
   */
  removeFilter(col: ColumnFilter) {
    this.reset();
    this.cdmTable.filteredValue = col.tempSelections = undefined;
    this.cdmTable.sortField = null;
    this.cdmPageFilter.filters = this.cdmPageFilter.filters.filter(item => item.field !== col.field);
    col.selectedItems = [];
    col.uniqueDataNonLazyFilter = [...col.uniqueData];
    this.checkForFilters();
    this.handleVirtualReset();
  }

  checkForFilters() {
    this.hasFiltersApplied = this.cdmPageFilter.filters.length > 0;
  }

  private setPageFilters(colField) {
    let result;
    let col = this.cols.find(col => col.field === colField);
    if (col?.tempSelections?.length) {
      switch (col.filterType) {
        case 'text':
          this.setInputSelectItems(col);
          break;
        case 'text-extra':
          this.setInputSelectItems(col);
          break;
        case 'list':
          if (col.header == 'SI' || col.header == 'AMA Description' || col.header == 'APC') {
            this.setMultiSelectedItemsArrValue(col);
            break;
          }
          this.setMultiSelectedItems(col);
          break;
        case 'date':
          this.setSelectedDates(col);
          break;
      }

      result = {
        selectedItems: [...col.selectedItems.flat()].filter(item => item),
        cbFilterField: col.cbFilterField,
        cbFilterType: col.cbFilterType,
        field: col.field,
        cbFilterField2: col.cbFilterField2
      };
    } else {
      //Else block added for when user clears filter and hits apply
      result = {
        selectedItems: [],
        cbFilterField: col.cbFilterField,
        cbFilterType: col.cbFilterType,
        field: col.field,
        cbFilterField2: col.cbFilterField2
      };
    }
    let resultFound = false;
    this.cdmPageFilter.filters.forEach(pageFilter => {
      if (result && pageFilter.field == result.field) {
        Object.assign(pageFilter, result);
        pageFilter.isLazyLoaded = pageFilter.isLazyLoaded || this.isLazy;
        resultFound = true;
      }
    });
    if (!resultFound) {
      result.isLazyLoaded = this.isLazy;
      this.cdmPageFilter.filters.push(result);
    }
    this.cdmPageFilter.filters.filter(item => item && item.selectedItems.length);
    this.checkForFilters();
  }

  /**
   * Clears filters for all columns
   */
  clearAllFilters() {
    this.cdmPageFilter.paging.pageSize = this.cdmTablePageSize;
    this.reset();
    this.cols.forEach(col => {
      col.selectedItems = [];
      col.tempSelections = col.filterType === 'date' ? undefined : [];
      col.uniqueDataNonLazyFilter = [...col.uniqueData];
    });
    this.cdmPageFilter.filters = [];
    localStorage.removeItem('cdmtable');
    this.hasFiltersApplied = false;
    this.cdmTable.filteredValue = null;
    this.cdmTable._sortField = null;
    this.cdmTable._sortOrder = this.cdmTable.defaultSortOrder;
    this.handleVirtualReset();
  }

  /**
   * Handler for when the multiselect loses focus.
   * @description When bluring away or closing the multiselect, a check is made to compare the
   * tempSelectedItems with the (filter applied) selectedItems.  This is to ensure that anything checked
   * or unchecked, but not yet applied with a filter, gets reset to the selectedItems.
   */
  onMultiSelectHide(col: ColumnFilter) {
    if (col.tempSelections && col.selectedItems.length !== col.tempSelections.length) {
      col.selectedItems.length ? (col.tempSelections = [...col.selectedItems]) : (col.tempSelections = []);
    }
  }

  onMultiSelectShow(col: ColumnFilter) {
    this.multiSelectLoading = true;
    const index = this.cols.findIndex(column => column.field === col.field);
    this.cols[index].uniqueDataNonLazyFilter = [];
    this.subs.sink = this.facade.fetchColumnUniqueFilterList(col, this.cdmPageFilter).subscribe(
      (data: string[]) => {
        data = data.filter(i => Utils.hasValue(i));
        if (col.field === 'chargeCategoryCommaList') {
          this.handleCategoryColumnList(data, col);
        } else if (col.field === 'tenantDisplayName') {
          this.handleTenantColumnList(data, col);
        } else if (col.field === 'si' || col.field === 'paymentRate' || col.field === 'amaDescription') {
          this.setColumnListDataArrValue(data, col.field);
        } else {
          this.setColumnListDataStructure(data, col.field);
        }
        this.multiSelectLoading = false;
      },
      err => {
        this.multiSelectLoading = false;
        this.notificationService.notify(messages.error.fetchUniqueColFilterList, NotificationType.Error, err);
        this.appInsightsService.trackException({
          exception: err,
          error: new Error(`Error getting column filter list. Col: ${col.header}.`)
        });
      }
    );
  }

  private handleTenantColumnList(data: string[], col: ColumnFilter) {
    const tenantList = this.facade.getTenantListSnapShot();
    const validTenants = this.facade.filterValidTenantStandings(tenantList).map(item => item.tenantName);
    const validColumnData = data.filter(item => {
      return validTenants.includes(item);
    });
    let tenantData = tenantList.filter(item => {
      return validColumnData.includes(item.tenantName);
    });
    this.setColumnListData(tenantData, col.field);
  }

  private handleCategoryColumnList(data: string[], col: ColumnFilter) {
    const categoryDictionary = this.facade.getServiceLineSpecificCategories(this.selectedServiceLine);
    const categories = data
      .map(item => {
        if (categoryDictionary[item] && Utils.hasValue(categoryDictionary[item].name)) {
          if (!categoryDictionary[item].displayName) {
            return { displayName: categoryDictionary[item].name, docId: categoryDictionary[item].docId };
          }
          return { displayName: categoryDictionary[item].displayName, docId: categoryDictionary[item].docId };
        }
      })
      .sort(this.sortFilterList);
    this.setColumnListData(categories, col.field);
  }

  /**
   * Sorts the list of categories by name.
   * @param categories - Categories to sort.
   */
  private sortFilterList(a, b) {
    return a.displayName.toLowerCase() > b.displayName.toLowerCase()
      ? 1
      : b.displayName.toLowerCase() > a.displayName.toLowerCase()
      ? -1
      : 0;
  }

  /**
   * Gets the categories for the selected rows to be displayed in the modal
   */
  getCategoriesForModal() {
    this.prepareSingleEditModal();
    this.prepareBulkEditModal();
  }

  private prepareBulkEditModal() {
    const chargeDescriptions = this.getChargeDescriptionsFromSelectedRows(this.selectedChargeDescriptionIds);
    const edits: ChargeDescriptionEdit[] = this.getChargeDescriptionToCategoryDict(chargeDescriptions);
    const cats = this.facade.getDistinctCategoriesFromBulkEdit(edits);
    const categoryIds = cats.map(c => c.docId).filter(x => !!x);
    this.categoriesForDescriptions = this.getCategoriesByCategoryIds(categoryIds);

    if (this.selectedServiceLine === 'all') {
      this.selectedSlCategoriesForDescriptions = this.categoriesForDescriptions;
    } else {
      this.selectedSlCategoriesForDescriptions = this.categoriesForDescriptions.filter(category => {
        if (!category?.name) {
          return false;
        }
        const isHB = category.name.includes('HB');
        const isPB = category.name.includes('PO');

        switch (this.selectedServiceLine) {
          case 'hb':
            return isHB;
          case 'pb':
            return isPB && !isHB;
          case 'drgv':
            return !isPB && !isHB;
          case 'cc':
            return isPB || isHB;
          default:
            return false;
        }
      });
    }
  }

  private prepareSingleEditModal() {
    this.createCatsForDescByServiceLine();
    this.setCategoriesForModalByServiceLine();
  }

  private createCatsForDescByServiceLine() {
    this.initAllCatsForDescStruct();
    this.categoriesForDescriptions.forEach(category => {
      if (category?.name && category.name.includes('HB')) {
        this.allCategoriesForDescriptions.hb.push(category);
      }
      if (category?.name && category.name.includes('PO') && !category.name.includes('HB')) {
        this.allCategoriesForDescriptions.pb.push(category);
      }
      if (category?.name && !category.name.includes('HB') && !category.name.includes('PO')) {
        this.allCategoriesForDescriptions.drgv.push(category);
      }
      if ((category?.name && category.name.includes('HB')) || category.name.includes('PO')) {
        this.allCategoriesForDescriptions.cc.push(category);
      }
    });
  }

  private initAllCatsForDescStruct() {
    this.allCategoriesForDescriptions.all = this.categoriesForDescriptions;
    this.allCategoriesForDescriptions.hb = [];
    this.allCategoriesForDescriptions.pb = [];
    this.allCategoriesForDescriptions.cc = [];
    this.allCategoriesForDescriptions.drgv = [];
  }

  private setCategoriesForModalByServiceLine() {
    switch (this.selectedServiceLine) {
      case 'hb':
        this.selectedSlCategoriesForDescriptions = this.allCategoriesForDescriptions.hb;
        break;
      case 'pb':
        this.selectedSlCategoriesForDescriptions = this.allCategoriesForDescriptions.pb;
        break;
      case 'drgv':
        this.selectedSlCategoriesForDescriptions = this.allCategoriesForDescriptions.drgv;
        break;
      case 'cc':
        this.selectedSlCategoriesForDescriptions = this.allCategoriesForDescriptions.cc;
        break;
      default:
        this.selectedSlCategoriesForDescriptions = this.allCategoriesForDescriptions.all;
        return;
    }
  }

  /**
   * Handler for filtering categories in the edit modal's autocomplete control
   */
  filterCategories(event) {
    switch (this.selectedServiceLine) {
      case 'hb':
        this.filteredResults = this.categories.filter(category => {
          if (category?.name && category.name.includes('HB')) {
            return category.name.toLowerCase().includes(event.query.toLowerCase());
          }
        });
        break;
      case 'pb':
        this.filteredResults = this.categories.filter(category => {
          if (category?.name && category.name.includes('PO') && !category.name.includes('HB')) {
            return category.name.toLowerCase().includes(event.query.toLowerCase());
          }
        });
        break;
      case 'drgv':
        this.filteredResults = this.categories.filter(category => {
          if (category?.name && !category.name.includes('HB') && !category.name.includes('PO')) {
            return category.name.toLowerCase().includes(event.query.toLowerCase());
          }
        });
        break;
      case 'cc':
        this.filteredResults = this.categories.filter(category => {
          if (category?.name && (category.name.includes('HB') || category.name.includes('PO'))) {
            return category.name.toLowerCase().includes(event.query.toLowerCase());
          }
        });
        break;
      default:
        this.filteredResults = this.categories.filter(category => {
          if (category?.name) {
            return category.name.toLowerCase().includes(event.query.toLowerCase());
          }
        });
    }
  }

  /**
   * Handler for selecting/deselecting rows for category editing
   */
  onToggleRowSelect() {
    this.enableEditButton = this.selectedRows.length > 0;
    this.selectedChargeDescriptionIds = this.selectedRows.map(chargeDescription => chargeDescription.docId);
    this.selectAllChecked =
      this.virtualCdmData.length === this.selectedRows.length && this.virtualCdmData.length <= 100;
  }

  clearAddAndDeleteList() {
    this.categoriesToAdd = [];
    this.categoriesToDelete = [];
  }

  /**
   * Dispatches an action to update the categories associated with the selected
   * chargeDescription(s)
   */
  applyCdmTableCategoryUpdate() {
    console.log('Applying CDM updates');
    if (this.editMode === this.singleUpdate) {
      this.singleEditUpdate();
    } else {
      this.bulkUpdate();
    }
  }

  private singleEditUpdate() {
    const updates: ChargeDescriptionSingleUpdateEdit = {
      categoryIdsToAdd: this.categoriesToAdd.map(c => c.docId),
      categoryIdsToDelete: this.categoriesToDelete.map(c => c.docId),
      chargeDescriptionIds: this.selectedChargeDescriptionIds,
      relationshipType: ''
    };
    this.setAllCategoriesByServiceLineAfterEdit();
    this.appInsightsService.trackEvent({
      name: 'apply cdm table category single update',
      properties: {
        updates: updates,
        user: this.user
      }
    });

    this.facade.applyCdmTableCategorySingleUpdate(updates);
    this.setCdmViewValuesAfterUpdate();
  }

  private bulkUpdate() {
    const updates: BulkChargeDescriptionListEdit = {
      chargeDescriptionEdits: this.getChargeDescriptionEdits()
    };

    this.setAllCategoriesByServiceLineAfterEdit();
    this.facade.applyCdmTableCategoryUpdate(updates);
    this.setCdmViewValuesAfterUpdate();

    this.appInsightsService.trackEvent({
      name: 'apply cdm table category update',
      properties: {
        updates: updates,
        user: this.user
      }
    });
  }

  private getChargeDescriptionEdits(): ChargeDescriptionEdit[] {
    const chargeDescriptions = this.getChargeDescriptionsFromSelectedRows(this.selectedChargeDescriptionIds);
    const catDocIdsForSl = this.selectedSlCategoriesForDescriptions.map(category => category.docId);
    const bulkEdits: ChargeDescriptionEdit[] = [];

    chargeDescriptions.map(chargeDesc => {
      const unaffectedSlCategories = this.getOtherSlCategories(chargeDesc.chargeCategoryIds);

      const edit: ChargeDescriptionEdit = {
        chargeDescriptionId: chargeDesc.docId,
        categoryIdsForEdit: [...new Set<string>(unaffectedSlCategories.concat(catDocIdsForSl))]
      };

      bulkEdits.push(edit);
    });

    return bulkEdits;
  }

  private getOtherSlCategories(docIds: string[]): string[] {
    if (this.selectedServiceLine === 'all') {
      return this.categoriesForDescriptions.map(cat => cat.docId);
    }

    return this.categoriesForDescriptions
      .filter(category => {
        if (!category?.name || !docIds.includes(category.docId)) {
          return false;
        }
        const isHB = category.name.includes('HB');
        const isPB = category.name.includes('PO');
        let isSlCategory = false;

        switch (this.selectedServiceLine) {
          case 'hb':
            isSlCategory = isHB;
            break;
          case 'pb':
            isSlCategory = isPB && !isHB;
            break;
          case 'drgv':
            isSlCategory = !isPB && !isHB;
            break;
          case 'cc':
            isSlCategory = isPB || isHB;
            break;
          default:
            isSlCategory = false;
            break;
        }
        return !isSlCategory;
      })
      .map(cat => cat.docId);
  }

  private setCdmViewValuesAfterUpdate() {
    let cdmToUpdateInView: CdmChargeDescription = null;
    this.selectedChargeDescriptionIds.forEach(cd => {
      this.virtualCdmData.forEach((cdm, i) => {
        if (this.virtualCdmData[i] && this.virtualCdmData[i].docId == cd) {
          cdmToUpdateInView = clonedeep(this.virtualCdmData[i]);
          if (this.editMode === this.singleUpdate) {
            this.setViewValuesSingleUpdateMode(cdmToUpdateInView);
          } else {
            cdmToUpdateInView.chargeCategoryNames = this.selectedSlCategoriesForDescriptions.map(c => c.displayName);
            cdmToUpdateInView.chargeCategoryIds = this.selectedSlCategoriesForDescriptions.map(c => c.docId);
          }
          cdmToUpdateInView.chargeCategoryCommaList = cdmToUpdateInView.chargeCategoryNames.join(', ');
          Array.prototype.splice.apply(this.virtualCdmData, [...[i, 1], cdmToUpdateInView]);
          this.virtualCdmData = [...this.virtualCdmData];
        }
      });
    });
  }

  private setViewValuesSingleUpdateMode(cdmToUpdateInView: CdmChargeDescription) {
    cdmToUpdateInView.chargeCategoryNames ??= [];
    if (cdmToUpdateInView.chargeCategoryIds) {
      this.getCategoriesOnCdmRecord(cdmToUpdateInView);
    }
    if (this.categoriesToAdd.length) {
      this.categoriesToAdd.forEach(cat => {
        if (!cdmToUpdateInView.chargeCategoryNames.includes(cat.displayName)) {
          cdmToUpdateInView.chargeCategoryNames = [...cdmToUpdateInView.chargeCategoryNames, cat.displayName];
          cdmToUpdateInView.chargeCategoryIds = [...cdmToUpdateInView.chargeCategoryIds, cat.docId];
        }
      });
    }
    if (this.categoriesToDelete.length && cdmToUpdateInView.chargeCategoryNames) {
      this.categoriesToDelete.forEach(cat => {
        const index = cdmToUpdateInView.chargeCategoryNames.indexOf(cat.displayName);
        if (index > -1) {
          cdmToUpdateInView.chargeCategoryNames.splice(index, 1);
          cdmToUpdateInView.chargeCategoryIds.splice(index, 1);
        }
      });
    }
  }

  private getCategoriesOnCdmRecord(cdmToUpdateInView: CdmChargeDescription) {
    const categoriesOnCdmRecord = [];
    const catDic = this.facade.getServiceLineSpecificCategories(this.selectedServiceLine);
    cdmToUpdateInView.chargeCategoryIds.forEach(id => {
      categoriesOnCdmRecord.push(catDic[id]);
    });
    cdmToUpdateInView.chargeCategoryNames = categoriesOnCdmRecord.map(c => c.displayName);
    cdmToUpdateInView.chargeCategoryIds = categoriesOnCdmRecord.map(c => c.docId);
  }

  private setAllCategoriesByServiceLineAfterEdit() {
    switch (this.selectedServiceLine) {
      case 'hb':
        this.allCategoriesForDescriptions.hb = this.selectedSlCategoriesForDescriptions;
        break;
      case 'pb':
        this.allCategoriesForDescriptions.pb = this.selectedSlCategoriesForDescriptions;
        break;
      case 'drgv':
        this.allCategoriesForDescriptions.drgv = this.selectedSlCategoriesForDescriptions;
        break;
      case 'cc':
        this.allCategoriesForDescriptions.cc = this.selectedSlCategoriesForDescriptions;
        break;
      default:
        this.allCategoriesForDescriptions.all = this.selectedSlCategoriesForDescriptions;
        break;
    }
  }

  /**
   * Clears the sorting on the grid.
   */
  resetSort() {
    if (localStorage.getItem('cdmtable')) {
      let state = JSON.parse(localStorage.getItem('cdmtable'));
      delete state.sortField;
      localStorage.setItem('cdmtable', JSON.stringify(state));
    }
    this.cdmTable._sortField = null;
    this.cdmTable._sortOrder = this.cdmTable.defaultSortOrder;
    this.cdmTable._multiSortMeta = null;
    this.cdmTable.tableService.onSort(null);
    this.fetchCdmData(this.createLazyLoadMetadata());
  }

  async changeServiceLine() {
    this.eventBus.setCDMTableLoading(true);
    if (this.selectedServiceLine == 'hb') {
      this.cdmPageFilter.doNotIncludeForServiceLine = this.facade.hbCategoryIds;
    } else if (this.selectedServiceLine == 'pb') {
      this.cdmPageFilter.doNotIncludeForServiceLine = this.facade.pbCategoryIds;
    } else if (this.selectedServiceLine == 'drgv') {
      this.cdmPageFilter.doNotIncludeForServiceLine = this.facade.drgvCategoryIds;
    } else if (this.selectedServiceLine == 'cc') {
      this.cdmPageFilter.doNotIncludeForServiceLine = this.facade.ccCategoryIds;
    } else {
      this.cdmPageFilter.doNotIncludeForServiceLine = [];
    }
    await this.clearSortAndFilters();
    this.facade.fetchCdmDataPaged(this.cdmPageFilter);
    this.lastCdmPageFilter = JSON.stringify(this.cdmPageFilter);
    this.reloadAfterExport = false;
  }

  /**
   * Clears the sort and filters for all columns
   */
  clearSortAndFilters() {
    if (localStorage.getItem('cdmtable')) {
      let state = JSON.parse(localStorage.getItem('cdmtable'));
      delete state.sortField;
      localStorage.setItem('cdmtable', JSON.stringify(state));
    }
    this.cdmTable._sortField = null;
    this.cdmTable._sortOrder = this.cdmTable.defaultSortOrder;
    this.cdmTable._multiSortMeta = null;
    this.cdmTable.tableService.onSort(null);
    this.cols.map(col => {
      col.selectedItems = [];
      col.tempSelections = col.filterType === 'date' ? undefined : [];
    });
    this.cdmPageFilter.filters = [];
    this.hasFiltersApplied = false;
    this.handleVirtualReset();
  }

  /**
   * Sets orderBy values for cdmPageFilter object that is sent for server side soring.
   */
  setOrderBy(event: any) {
    let orderByComparison = event.sortOrder === 1 ? 'ASC' : 'DESC';
    const { orderByField, orderByDirection } = this.cdmPageFilter.paging;
    let orderByDir = orderByDirection == '' ? 'ASC' : orderByDirection;
    let orderByFld = !event.sortField ? '' : event.sortField;
    if (orderByDir !== orderByComparison || orderByFld !== orderByField) {
      this.reset();
      if (this.pageCount > this.cdmTablePageSize) {
        this.virtualCdmData = Array.from({ length: this.cdmTablePageSize });
      } else {
        this.virtualCdmData = Array.from({ length: this.pageCount });
      }
      this.virtualCdmData = [...this.virtualCdmData];
    }
    this.cdmPageFilter.paging.orderByField = orderByFld;
    this.cdmPageFilter.paging.orderByDirection = event.sortOrder === 1 ? 'ASC' : 'DESC';
  }

  /**
   * Checks if selected filter dates are valid and if so this enables the apply fitler button
   * @param col date column
   */
  checkDateSelection(col: ColumnFilter) {
    return col.tempSelections?.some(d => d === null || d === undefined) || !col.tempSelections?.length;
  }

  /**
   * Removes state from local storage.
   */
  removeState() {
    this.hasLocalState = false;
    localStorage.removeItem('cdmtable');
    localStorage.removeItem('cdmTableColVisibility');
    this.getColumnState();
  }

  /**
   * Initializes the CDM Table data subscription.
   */
  private initCdmDataSubscription() {
    this.subs.sink = this.cdmTableData$.subscribe(
      results => {
        if (results) {
          this.cdmTableData = results;
          this.populateVirtualDataWithCdmData(this.cdmTableData);
          // if block is checking for server failure on export to excel
          if (this.exportingToCsvInProcess && !results.cdmData) {
            let checkOffset = this.cdmTableData.offset;
            if (typeof checkOffset == 'string') {
              return this.handelExportToExcelFailure();
            }
          }
        }
      },
      err => {
        this.eventBus.setCDMTableLoading(false);
        console.log(err);
      }
    );
  }

  private handelExportToExcelFailure() {
    this.exportFailure = true;
    this.cdmPageFilter.paging.pageSize = this.cdmTablePageSize;
    this.exportingToCsvInProcess = false;
    this.eventBus.setCDMTableLoading(false);
    this.notificationService.notify(messages.error.exportExcelFailure, NotificationType.Error);
  }

  /**
   * Populates Virtual CDM Table Data
   */
  private populateVirtualDataWithCdmData(cdmTableData: CdmTableData) {
    this.eventBus.setUpdatingUIDisplayValues(true);
    if (cdmTableData?.cdmData) {
      let cdmTd: CdmTableData = clonedeep(cdmTableData);
      let { cdmData, offset } = cdmTd;
      if (cdmData.length == 0 && offset == 0) {
        this.virtualCdmData = [];
        this.pageCount = 0;
        return;
      }
      Array.prototype.splice.apply(this.virtualCdmData, [...[offset, cdmData.length], ...cdmData]);
      this.virtualCdmData = [...this.virtualCdmData];
      setTimeout(() => {
        this.facade.modifyCdmDataForDisplay(cdmData, this.selectedServiceLine);
        Array.prototype.splice.apply(this.virtualCdmData, [...[parseInt(offset as any), cdmData.length], ...cdmData]);
        this.virtualCdmData = [...this.virtualCdmData];
        this.eventBus.setUpdatingUIDisplayValues(false);
        this.eventBus.setCDMTableLoading(false);
      }, 100);
    }
  }

  /**
   * Sets column filter list.
   */
  private setColumnListData(list: any, field: string) {
    const index = this.cols.findIndex(col => col.field === field);
    this.cols[index].uniqueData = [{ field: 'filterOnBlank', displayName: 'Filter On Blank' }, ...list];
    this.cols[index].uniqueDataNonLazyFilter = [{ field: 'filterOnBlank', displayName: 'Filter On Blank' }, ...list];
  }

  private setColumnListDataStructure(list: string[], field: string) {
    const index = this.cols.findIndex(col => col.field === field);
    let listStrut = list.map(item => {
      if (item) {
        return { field: item, displayName: item };
      }
    });
    this.cols[index].uniqueData = [{ field: 'filterOnBlank', displayName: 'Filter On Blank' }, ...listStrut];
    this.cols[index].uniqueDataNonLazyFilter = [
      { field: 'filterOnBlank', displayName: 'Filter On Blank' },
      ...listStrut
    ];
  }

  /**
   * Sets column filter list.
   */
  private setColumnListDataArrValue(data: string[], field: string) {
    let dictionary;
    let dicField = '';
    if (field === 'si') {
      dictionary = this.siDictionary;
      dicField = 'si';
    }
    if (field === 'paymentRate') {
      dictionary = this.apcDictionary;
      dicField = 'paymentRate';
    }
    if (field === 'amaDescription') {
      dictionary = this.amaDictionary;
      dicField = 'description';
    }
    const index = this.cols.findIndex(col => col.field === field);
    let noDupes = new Set();
    const mappedData = data
      .map(item => {
        if (
          this.cptDictionary[item] &&
          Utils.hasValue(this.cptDictionary[item][dicField]) &&
          !noDupes.has(this.cptDictionary[item][dicField])
        ) {
          let val = this.cptDictionary[item][dicField];
          noDupes.add(val);
          return { displayName: val, value: dictionary[val] };
        }
        if (
          this.hcpcsDictionary[item] &&
          Utils.hasValue(this.hcpcsDictionary[item][dicField]) &&
          !noDupes.has(this.hcpcsDictionary[item][dicField])
        ) {
          let val = this.hcpcsDictionary[item][dicField];
          noDupes.add(val);
          return { displayName: val, value: dictionary[val] };
        }
      })
      .sort((a, b) => {
        if (a.displayName && b.displayName) {
          return a.displayName.toLowerCase() > b.displayName.toLowerCase()
            ? 1
            : b.displayName.toLowerCase() > a.displayName.toLowerCase()
            ? -1
            : 0;
        }
      });

    this.cols[index].uniqueData = [...mappedData];
    this.cols[index].uniqueDataNonLazyFilter = [...mappedData];
  }

  /**
   * This will force a close/hide event on the current multiselect
   */
  closeMultiSelect(multiSelectFilter: MultiSelect, event: any) {
    if (multiSelectFilter) {
      multiSelectFilter.close(event);
    }
  }

  closeDatePicker(datePicker: Calendar) {
    datePicker.hideOverlay();
  }

  /**
   * Sets the selected items for textbox filters
   */
  private setInputSelectItems(col: ColumnFilter) {
    col.selectedItems = [];
    if (col.cbFilterType == 'EXACT') {
      (col.tempSelections as any) = (col.tempSelections as any).replace(/\$|,/g, '');
    }
    col.selectedItems.push(col.tempSelections.toString());
  }

  /**
   * Sets the selected items for multiselect filters.
   * @description If the filter is filterOnBlank or the column is 'Type', their
   * object shapes are different, so the value set to selected is different from
   * the others.
   */
  private setMultiSelectedItems(col: ColumnFilter) {
    col.selectedItems = [...col.tempSelections].map(item => {
      return item.field === 'filterOnBlank' || col.field === 'descriptionType' ? item.field : item[col.listBoxKey];
    });
  }

  /**
   * Sets the selected items for multiselect filters.
   * @description If the filter is filterOnBlank or the column is 'Type', their
   * object shapes are different, so the value set to selected is different from
   * the others.
   */
  private setMultiSelectedItemsArrValue(col: ColumnFilter) {
    col.selectedItems = [...col.tempSelections].map(item => {
      return item.field === 'filterOnBlank' || col.field === 'descriptionType' ? item.field : item[col.listBoxKey];
    });
  }

  /**
   * Sets date to start and end of the day for the selected date range.
   * @param col column
   */
  private setSelectedDates(col: ColumnFilter) {
    col.selectedItems = col.tempSelections.map((date, i) => {
      if (i == 0) {
        date.setHours(0, 0, 0, 0);
      } else {
        date.setHours(23, 59, 59, 999);
      }
      return date.toISOString();
    });
  }

  /**
   * Resets the state of the filters, filter events and grid, and
   * scrolls back to top.
   */
  private reset() {
    this.cdmTable.clearState();
    this.cdmTable.first = 0;
    this.cdmTable.resetScrollTop();
  }

  /**
   * Creates a lazy load event object.
   */
  createLazyLoadMetadata(): any {
    if (this.cdmTable) {
      return {
        first: this.cdmTable.first,
        rows: this.cdmTablePageSize,
        sortField: this.cdmTable.sortField,
        sortOrder: this.cdmTable.sortOrder,
        filters: this.cdmTable.filters,
        globalFilter:
          this.cdmTable.filters && this.cdmTable.filters['global']
            ? (this.cdmTable as any).filters['global'].value
            : null,
        multiSortMeta: this.cdmTable.multiSortMeta
      };
    }
  }

  /**
   * Fetches the data used for predetermined multiselect values to filter by
   */
  private fetchFilterValues() {
    this.subs.sink = this.siDictionary$.subscribe(si => {
      this.siDictionary = clonedeep(si);
    });
    this.subs.sink = this.amaDescrDictionary$.subscribe(ama => {
      this.amaDictionary = clonedeep(ama);
    });
    this.subs.sink = this.apcDictionary$.subscribe(apc => {
      this.apcDictionary = clonedeep(apc);
    });
    this.subs.sink = this.revenueCodeDictionary$.subscribe(
      dictionary => (this.revCodeDictionary = clonedeep(dictionary))
    );
    this.subs.sink = this.hcpcsDictionary$.subscribe(dictionary => (this.hcpcsDictionary = clonedeep(dictionary)));
    this.subs.sink = this.cptDictionary$.subscribe(dictionary => (this.cptDictionary = clonedeep(dictionary)));
    this.setColumnListData(
      [
        { field: CdmTypes.HIM, displayName: CdmTypes.HIM },
        { field: CdmTypes.Hospital, displayName: CdmTypes.Hospital },
        { field: CdmTypes.Physician, displayName: CdmTypes.Physician }
      ],
      'descriptionType'
    );
    this.initCdmDataSubscription();
  }

  /**
   * Removes state properties from local storage.
   */
  private removeStatePropertiesFromStorage() {
    if (localStorage.getItem('cdmtable')) {
      let state = JSON.parse(localStorage.getItem('cdmtable'));
      delete state.selection;
      delete state.sortField;
      localStorage.setItem('cdmtable', JSON.stringify(state));
    }
  }

  /**
   * Gets charge descriptions from selected rows.
   */
  private getChargeDescriptionsFromSelectedRows(selectedRows: string[]): ChargeDescription[] {
    return selectedRows.map((docId: string) => this.virtualCdmData.find(c => c?.docId === docId));
  }

  private getChargeDescriptionToCategoryDict(chargeDescriptions: ChargeDescription[]): ChargeDescriptionEdit[] {
    return chargeDescriptions
      .filter(item => item)
      .reduce((list, chargeDesc) => {
        list.push({
          chargeDescriptionId: chargeDesc.docId,
          categoryIdsForEdit: chargeDesc.chargeCategoryIds
        });
        return list;
      }, []);
  }

  /**
   * Gets categories from array of category Ids.
   */
  private getCategoriesByCategoryIds(categoryIds: string[]) {
    const categories = categoryIds
      .map(id => this.categories.find(category => category.docId === id))
      .filter(category => category);
    const uniqueSet = new Set(categories).keys();
    return [...uniqueSet];
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
