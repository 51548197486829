import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Category, CategoryN1qlSearchResult, CategoryReduced, PpfDictionary } from 'src/app/_shared/models';
import { UserProfileReduced } from 'src/app/_shared/models/user-profile-reduced';
import { environment } from 'src/environments/environment';
import { Cacheable } from 'ts-cacheable';
import { CategoryDeltaRequest, CategoryNameWithRuleTitlesList, CdmPageFilter, Tenant } from '../shared/models';

@Injectable({
  providedIn: 'root'
})
export class DataApiService {
  api = `${environment.config.dataApiUrl}api/${environment.config.dataApiVersion}`;

  constructor(private http: HttpClient) {}

  @Cacheable({
    maxCacheCount: 25
  })
  getUserByUserId(userId: string): Observable<UserProfileReduced> {
    const euid = encodeURIComponent(userId);
    return this.http.get<UserProfileReduced>(`${this.api}/users/${euid}`);
  }

  getCategoryNameWithRuleTitlesList(): Observable<CategoryNameWithRuleTitlesList[]> {
    return this.http.get<CategoryNameWithRuleTitlesList[]>(`${this.api}/rules/categoryRulesV2`);
  }

  fetchUserInfo(): Observable<any> {
    return this.http.get<any>(`${this.api}/users/me`);
  }

  fetchHcpcsStandard(): Observable<any> {
    return this.http.get<any>(`${this.api}/charges/hcpcsStandard`);
  }

  fetchAllTenants(): Observable<Tenant[]> {
    return this.http.get<Tenant[]>(`${this.api}/tenant/allTenants`);
  }

  fetchRevCodes(): Observable<any> {
    return this.http.get<any>(`${this.api}/rules/revCodes`);
  }

  fetchCategories(): Observable<CategoryReduced[]> {
    return this.http.get<CategoryReduced[]>(`${this.api}/charges/categoryreduced`);
  }

  getChargeCategoryById(id: string): Observable<Category> {
    return this.http.get<any>(`${this.api}/charges/category/${id}`);
  }

  fetchCategoryVersions(id: string): Observable<Category[]> {
    return this.http.get<any>(`${this.api}/charges/category/${id}/versions`);
  }

  fetchActiveRunningTenants(): Observable<String[]> {
    return this.http.get<String[]>(`${this.api}/charges/activeRunningTenants`);
  }

  fetchChargeDescriptionsByIds(docIds: String[]): Observable<any> {
    return this.http.post<any>(`${this.api}/charges/descriptions-by-ids`, docIds);
  }

  fetchChargeCategoryDelta(request: CategoryDeltaRequest): Promise<any> {
    return this.http.post<any>(`${this.api}/charges/category-delta`, request).toPromise();
  }

  getElasticTaskStatus(ids: string[]): Observable<any> {
    return this.http.get<any>(`${this.api}/elastic-tasks/${ids.join(',')}`);
  }

  cancelElasticTask(id: string): Observable<any> {
    return this.http.post<any>(`${this.api}/elastic-tasks/${id}/cancel`, null);
  }

  fetchCdmDataPaged(request: CdmPageFilter): Observable<any> {
    return this.http.post<any>(`${this.api}/charges/cdm-table`, request);
  }

  fetchCdmDataPagedExport(request: CdmPageFilter): Observable<any> {
    return this.http.post<any>(`${this.api}/charges/cdm-table/export`, request);
  }

  fetchCdmUniqueColumnData(request: CdmPageFilter, columnField: string, cbFilterField: string): Observable<any> {
    const params = new HttpParams().append('columnField', columnField).append('cbFilterField', cbFilterField);
    return this.http.post<any>(`${this.api}/charges/cdm-table/filter-values`, request, { params });
  }

  searchAllCategoriesN1ql(n1qlSearchTerm: string): Observable<CategoryN1qlSearchResult[]> {
    const params = new HttpParams().append('searchTerm', n1qlSearchTerm);
    return this.http.get<CategoryN1qlSearchResult[]>(`${this.api}/charges/categoryN1qlSearch`, { params });
  }

  fetchPpfsWithCategories(): Observable<PpfDictionary> {
    return this.http.get<PpfDictionary>(`${this.api}/postprocessingfilter/withCategories`);
  }
}
