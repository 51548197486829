import { Category } from 'src/app/_shared/models';
import { CdmChargeDescription, ChargeDescription } from '../../_shared/models/charge-description';
import { TenantStandingList } from './enums';

/****************************
 *         CLASSES
 ***************************/

export class QueryValue {
  values?: string[] = [];
  filterOnBlank = false;
}

export class QueryParameters {
  categories = new QueryValue();
  cpt = new QueryValue();
  hcpcs = new QueryValue();
  revenueCode = new QueryValue();
  tenants = new QueryValue();
  type = new QueryValue();
}
export class CategoryDelta {
  addDescriptionDocId: string[];
  descriptionsInAnotherCategory: ChargeDescription[];
  descriptionsNoChangeFromN1QL: ChargeDescription[];
  descriptionsNoChange: ChargeDescription[];
  noChangeCount: number;
  descriptionsToAdd: ChargeDescription[];
  descriptionsToRemove: ChargeDescription[];
  descriptionsToAddFromN1QL: ChargeDescription[];
  descriptionsToRemoveCount: number;
  descriptionsToAddCount: number;
}
export class CategoryDeltaRequest {
  elasticQuery: string;
  chargeCatDocId: string;
  manualExcludeIds: string[];
  manualIncludeIds: string[];
}

export class ChargeDescriptionUpdatesByServiceLine {
  all: Category[] = [];
  hb: Category[] = [];
  pb: Category[] = [];
  drgv: Category[] = [];
  cc: Category[] = [];
}

/****************************
 *      INTERFACES
 ***************************/

export interface CategoryDeltaReport {
  descriptionsToAdd: CdmChargeDescription[];
  descriptionsToRemove: CdmChargeDescription[];
  countToAdd: number;
  countToRemove: number;
  countTotal: number;
  countNoChange: number;
}

export interface ChargeDescriptionListEdit {
  chargeDescriptionIds: string[];
  categoryIdsForEdit: string[];
  relationshipType: string;
  modifiedBy?: string;
}

export interface BulkChargeDescriptionListEdit {
  chargeDescriptionEdits: ChargeDescriptionEdit[];
  modifiedBy?: string;
}

export interface ChargeDescriptionEdit {
  chargeDescriptionId: string;
  categoryIdsForEdit: string[];
}

export interface ChargeDescriptionSingleUpdateEdit {
  chargeDescriptionIds: string[];
  categoryIdsToAdd: string[];
  categoryIdsToDelete: string[];
  relationshipType: string;
  modifiedBy?: string;
}

export interface ManualCdm {
  tenant: string;
  revCode: string;
  chargeCode: string;
  description: string;
  hcpcs: string;
  cpt: string;
}

export interface SideNavOptions {
  bottom: number;
  fixed: boolean;
  top: number;
}

export interface Tenant {
  displayName: string;
  tenantName: string;
  standing: TenantStandingList;
}

export interface ChargeDescriptionListEdit {
  chargeDescriptionIds: string[];
  categoryIdsForEdit: string[];
  relationshipType: string;
  modifiedBy?: string;
}

export class CdmPageFilter {
  paging?: CdmPagingInfo;
  filters?: CdmFilterInfo[];
  doNotIncludeForServiceLine?: string[];
}

export interface CdmPagingInfo {
  pageSize?: number;
  orderByField?: string;
  orderByDirection?: string;
}

export interface CdmFilterInfo {
  selectedItems?: string[];
  cbFilterField?: string;
  cbFilterType?: string;
  isLazyLoaded?: boolean;
  field?: string;
  cbFilterField2?: string;
}

export interface CdmTableResponse {
  totalCount: number;
  chargeDescriptions: CdmChargeDescription[];
}

export interface JobStatus {
  id: string;
  tenantName: string;
  chargeCatId: string;
  status: string;
  type: string;
  createdDate: string;
  updatedDate: string;
  cdmTaskStatus?: string;
}

export interface CategoryNameWithRuleTitlesList {
  categoryName: string;
  ruleTitles: string[];
}
