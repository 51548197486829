import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Category } from 'src/app/_shared/models';
import { UserProfileReduced } from 'src/app/_shared/models/user-profile-reduced';
import { QueryParameters } from '../shared/models';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {
  private revertN1QLStatementSubject = new BehaviorSubject(null);
  private sortN1QLCodesSubject = new BehaviorSubject(null);
  private toggleTopSliderSubject = new BehaviorSubject<boolean>(false);
  private cdmTableQueryParams = new BehaviorSubject<QueryParameters>(new QueryParameters());
  private categoryVersions = new BehaviorSubject<Category[]>([]);
  private versionEditLog = new BehaviorSubject<string>(null);
  private formatN1QLSubject = new BehaviorSubject(null);
  private savingN1QLSubject = new BehaviorSubject<boolean>(false);
  private isCDMTableLoadingSubject = new BehaviorSubject<boolean>(false);
  private isCDMTableUpdatesLoadingSubject = new BehaviorSubject<boolean>(false);
  private isUpdatingUIDisplayValues = new BehaviorSubject<boolean>(false);
  private removingManualIncludeSubject = new BehaviorSubject<boolean>(false);
  private removingManualExcludeSubject = new BehaviorSubject<boolean>(false);
  private usersModifiedByVersionDictionary = new BehaviorSubject(null);

  categoryVersions$ = this.categoryVersions.asObservable();

  setUpdatingUIDisplayValues(loading: boolean) {
    this.isUpdatingUIDisplayValues.next(loading);
  }

  getUpdatingUIDisplayValues() {
    return this.isUpdatingUIDisplayValues.asObservable();
  }

  // Revert N1QL statement
  // -----------------------------------
  revertN1QLStatement() {
    this.revertN1QLStatementSubject.next(null);
  }

  revertN1QLStatement$() {
    return this.revertN1QLStatementSubject.asObservable();
  }

  // Sort N1QL codes
  // -----------------------------------
  sortN1QLCodes() {
    this.sortN1QLCodesSubject.next(null);
  }

  sortN1QLCodes$() {
    return this.sortN1QLCodesSubject.asObservable();
  }

  // Format N1QL codes
  // -----------------------------------
  formatN1QL() {
    this.formatN1QLSubject.next(null);
  }

  formatN1QL$() {
    return this.formatN1QLSubject.asObservable();
  }

  // Toggle Top Slider
  // -----------------------------------
  toggleTopSlider() {
    this.toggleTopSliderSubject.next(!this.toggleTopSliderSubject.getValue());
  }

  toggleTopSlider$() {
    return this.toggleTopSliderSubject.asObservable();
  }

  // CDM Table Query Parameters
  // -----------------------------------
  setCdmTableQueryParameters(parameters: QueryParameters) {
    this.cdmTableQueryParams.next(parameters);
  }

  cdmTableQueryParameters$() {
    return this.cdmTableQueryParams.asObservable();
  }

  // Category Versions
  // -----------------------------------
  setCategoryVersions(versions: Category[]) {
    this.categoryVersions.next(versions);
  }

  categoryVersionsSnapShot() {
    return this.categoryVersions.getValue();
  }

  // usersModifiedBy Versions
  // -----------------------------------
  setUsersModifiedByVersionDictionary(userDic: Record<string, UserProfileReduced>) {
    this.usersModifiedByVersionDictionary.next(userDic);
  }

  usersModifiedByVersionDictionary$() {
    return this.usersModifiedByVersionDictionary.asObservable();
  }

  usersModifiedByVerDicSnapShot() {
    return this.usersModifiedByVersionDictionary.getValue();
  }

  //Version Edit Log
  // -----------------------------------
  setVersionEditLog(versionEditLog: string) {
    this.versionEditLog.next(versionEditLog);
  }

  versionEditLog$() {
    return this.versionEditLog.asObservable();
  }

  versionEditLogSnapShot() {
    return this.versionEditLog.getValue();
  }

  // N1QL Editor save
  // -----------------------------------
  setSavingN1QL(saving: boolean) {
    this.savingN1QLSubject.next(saving);
  }

  savingN1QL$() {
    return this.savingN1QLSubject.asObservable();
  }

  setCDMTableLoading(loading: boolean) {
    this.isCDMTableLoadingSubject.next(loading);
  }

  getCDMTableLoading() {
    return this.isCDMTableLoadingSubject.asObservable();
  }

  setCDMTableUpdatesLoading(loading: boolean) {
    this.isCDMTableUpdatesLoadingSubject.next(loading);
  }

  getCDMTableUpdatesLoading() {
    return this.isCDMTableUpdatesLoadingSubject.asObservable();
  }

  //Manual Include Exlcudes
  getRemovingManualInclude$() {
    return this.removingManualIncludeSubject.asObservable();
  }

  setRemovingManualInclude(isLoading: boolean) {
    this.removingManualIncludeSubject.next(isLoading);
  }
  getRemovingManualExclude$() {
    return this.removingManualExcludeSubject.asObservable();
  }

  setRemovingManualExclude(isLoading: boolean) {
    this.removingManualExcludeSubject.next(isLoading);
  }
}
