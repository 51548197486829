import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';

import { UserProfileReduced } from 'src/app/_shared/models/user-profile-reduced';
import { UserInfo } from 'src/app/_shared/models/userInfo';
import { DataApiService } from 'src/app/charge-cat/services/data-api.service';
import { NotificationType } from 'src/app/charge-cat/shared/enums';
import { messages } from 'src/app/charge-cat/shared/messages';
import { NotificationService } from '../../../_core/services/notification.service';
import { ApplicationInsightsService } from '../../services/app-insights-service.service';
import { FetchUserInfo } from './user.actions';
import { UserState } from './user.state';

@Injectable({
  providedIn: 'root'
})
export class UserStoreFacade implements OnDestroy {
  private subs = new SubSink();

  constructor(
    private store: Store,
    private notificationService: NotificationService,
    private dataApiService: DataApiService,
    private appInsightsService: ApplicationInsightsService
  ) {}

  /**
   * @Selector Gets the object created by Auth0 authentication.
   */
  userInfo(): Observable<UserInfo> {
    return this.store.select(UserState.userInfo);
  }

  /**
   * @Action Fetches the user info object from the API which
   * holds information such as roles and imgURL.
   */
  fetchUserInfo() {
    this.subs.sink = this.dataApiService.fetchUserInfo().subscribe(
      result => {
        const { user } = result;
        this.store.dispatch(new FetchUserInfo(user));
      },
      err => {
        this.notificationService.notify(messages.error.fetchUserProfile, NotificationType.Error, err);
        this.appInsightsService.trackException({
          exception: err,
          error: new Error('Error fetching user info')
        });
      }
    );
  }

  /**
   * Data API called used for getting the "last modified by" info used
   * for diplaying the user info for a selected category.
   */
  fetchUserByUserId(userId: string): Observable<UserProfileReduced> {
    return this.dataApiService.getUserByUserId(userId);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
