export const environment = {
  appVersion: '2024-08-23-v.141731',
  production: true,
  config: {
    APPINSIGHTS_INSTRUMENTATIONKEY: '5032a1f0-8bf5-4204-b86c-251915dc3503',
    dataApiUrl: 'https://riapi.ppe.cloudmed.com/', // Data API
    functionAppUrl: 'https://rieppeassurancescalafunctionapp.azurewebsites.net',
    functionAppKey: 'VRFp/EL5OnZliQng/1Qtu5eF8VQ9aeaCBsYfkXkVgINq3WbsKoAvvA==',
    dataApiVersion: 'v1.13',
    auth0: {
      domain: 'auth.ppe.cloudmed.com',
      clientId: 'gpC62vjCbHlnWyyIZyxIB6YRJcKjzV5C',
      audience: 'ppe.cloudmed'
    }
  }
};
